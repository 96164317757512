

#root {
  overflow-x: hidden;
}
a{
text-decoration: none;
}
.list-group-item{
  border:none;
}

.aboutbg{
    position:absolute;
    height:150px;
    width:250px;
    padding-top: 10px;
    padding-left: 25px;
}
.aboutContent{
    height:100vh;
    width:90vw;
    padding: 1rem;
    padding-top:0.2rem;
    font-size: 3rem;
    font-weight: 700;
    display:flex;
    flex-direction: column;
    justify-content: center;
    border-bottom:2px;
}
.hello{
    font-size:1.75rem;
    font-weight:500;
}
.aboutname{
    color:orange;
    font-size: 3rem;
    padding-top: 0.2rem;
    }
.aboutpara{
    font-size: 1.5rem;
    font-weight: 500;
}
.aboutbtn{
    background:orange;
    margin:1rem 0;
    padding:0.75rem 2rem;
    height:90px;
    border-radius: 40px;
    border:none;
}

.center{
    text-align:center;
    padding-top: 2rem;
    display:flex;
    flex-direction: column;
}
.center22{
  text-align:center;
  display:flex;
  flex-direction: column;
  padding-top:1vw;
}
.center23{
  text-align:center;
  display:flex;
  flex-direction: column;
  padding-top:2vw;
}
.center11{
  text-align:center;
  display:flex;
  flex-direction: column;
  padding-top: 2vw;
}
.text-bg-dark1{
  .text-bg-dark {
    color: #fff!important;
    background-color: white;
}
}
.center1{
  text-align:center;
  display:flex;
  flex-direction: column;
}
.center2{
  text-align: center;
}
.w{
  width:100vw;
}
.aboutheading{
    font-size: 3rem;
}
.aboutheadname{
    color:orange;
    font-size: 5rem;
}
.img{
    text-align: center;
    padding-right:220px ;

}
.aboutheadname1{
    font-size: 3rem;
    color: orange;
}


.ad{
    text-align: center;
    height:fit-content;
   
  
}

.mw{
    width:100dvw;
}

.font1{
    font-size: 2rem;
    font-weight: 700;
}
.rup{
    height:170px;
}
.left{
    text-align: left;
    /* padding-left:6rem; */
    font-weight: 700;
    color: white;
}
.sony{
    height:80px;
}
.watch {
    display: flex;
    align-items: center;
  }
  .about{
    height:100vh;
  }
  .card{
    border: none;
    /* padding-top:2rem; */
    text-align: center;
    border-radius: 0px;
  }
  .abouttext{
    font-size:3rem;
  }
  .card-img-overlay{
    text-align: left;
    /* background-image: url("../Intro/imgintro.png"); */
    
  }
  .card-text1{
    padding-top:2rem;
  }
  .introtext{
    font-size:1.75rem;
    font-weight:500;
  }
  .introimg{
    opacity:0.6;
  }
  .aboutimg{
    opacity:0.6;
  }
  .ad1{
    /* background-image: url("/Users/vedantsahai/Documents/yadnesh_website/yadnesh_website/public/ad.png"); */
    background-color: black;
    background-size: contain;
    background-position: 220px 15px;
   }
  .small{
    font-size: 1.5rem;
  }
  .pspons{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust min-width and columns as needed */
    grid-gap: 10px; /* Add spacing between cards */
    justify-items: center;
  }
  .sponsors{
    height:40vh;
    padding-top: 1rem;
  }
  
.pad1{
  padding-top:7rem;
}
.pad2{
  padding-top:1.5rem;
}
.signup{
  padding-top:0rem;
}
.bg5{
  background-color:white;
}
.si{
  height:auto;
  width:auto;
}
.hor{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap:20px

  
}
.siz{
height:50px;
width:50px;
}
.sochead{
  padding-top:0rem;
}
.bg5{
  color:white;
  background-color: #1e1c1c;
}

.footer{
  padding-top:3rem;
}
.padnav{
  padding-left: 4rem;
}
.siznav{
  height:60px;
  width:90px;
}

.intro{
  height:fit-content;
    width: 100dwh;
  position: relative;
}
.background {
  z-index: 0;
  position: "relative";
}
.foreground {
  background-color: rgba(0,0,0,0);
}
/* .introCardImg{
 
  object-fit: cover;
  width: 100px;
  height: 200px;
} */
.gridlay{
  display:grid;
  grid-template-columns:repeat(3,1fr);
  gap:120px;
  padding-left: 15px;
  padding-right:15px;
  padding-top: 15px;
  padding-bottom: 15px;
  
}
@media only screen and (max-width: 1120px)  {
  .gridlay {
    grid-template-columns:repeat(1,1fr);
    gap:70px;
    padding-left: 5px;
    padding-right:5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.v{
  display:flex;
  flex-direction:row;
}
@media only screen and (max-width: 900px) {
  .v {
    display:flex;
    flex-direction: column;
    align-items:center
  }
  .margincont{
    margin:0px;
  }
}

.team{
  /* background-image:url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSsDP3NlFGAlMIeNoSD6yp3jf08B0DVQXICod_x8Q7CDC9y2SZOoMtvhrnJhjx_FnA0Mk&usqp=CAU"); */
  background-size: cover;
  background-position: center;
  height: 100vh;
  /* background-color:rgb(123, 18, 18); */
  background-color:midnightblue;
 
}
.title5{
letter-spacing: 20px;
color:white;
font-size: 5vw;
}
.vkv{
color:#363636
}
.title4{
  letter-spacing: 10px;
color:white
}
.top{
  padding-top: 2rem;
}

.card1{
  height: 270px;
  background-color:rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px) brightness(1.25) contrast(113%) saturate(115%);
  transition:700ms ease-in-out;
  width:18rem;
  }
.card1:hover{
  transform:scale(1.05);
  transition:700ms ease-in-out;
}  
.anim{
  transition:700ms ease-in-out;
}
.anim:hover{
  transform:scale(1.05);
  transition:700ms ease-in-out;
}
.top1{
  padding-top: 1.5rem;
}
.margincont{
  margin:70px
}
.bg7{
  background-color: transparent;
}
.card4{
  height: 270px;
  background-color:rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px) brightness(1.25) contrast(113%) saturate(115%);
  transition:700ms ease-in-out;
  width:18rem;
  }
.card4:hover{
  transform:scale(1.05);
  transition:700ms ease-in-out;
}
.sponsors{
  height: fit-content;
  position:relative;
  background-color: black;
  color:white;
}
.card2{
  height: 270px;
  background-color:rgba(255,255,255, 0);
  backdrop-filter:  blur(2px) ;
  transition:700ms ease-in-out;
  width:230px;
  text-align: center;
}
.card2:hover{
  transform:scale(1.05);
  transition:700ms ease-in-out;
} 
.imgsize{
height:500px;
width:100px;
align-items: center;
} 
.teamcard{
  max-width: 540px
}
.card-group{
  padding-top: 25px;
}
.padspons{
  padding-top: 10px;

}
.padbot{
  padding-bottom:10px;
}
.textcol{
  color:rgb(219, 217, 217);
  font-weight:10vw;
  font-size:3vw;
}
.col{
  color:rgb(219, 217, 217);
}
.left1 {
  position: absolute;
  top: 0;
  left: 0;
  /* Remove fixed width in favor of viewport units */
  width: calc(75vw - 100px); /* Account for potential margin/padding */
  height: 100vh;
  font-size:3vw;
}
.title1 {
  font-size:7vw;
  font-weight: 1000;
  color:rgb(31, 31, 147);
  letter-spacing: 5px;
}
.titlev{
  font-size:6vw;
  font-weight: 700;
  color:rgb(31, 31, 147);
  letter-spacing: 5px;
}
.text-bg{
 background-color: darkslateblue;
}
.title2{
  font-size:7vw;
  font-weight: 1000;
  color:bisque;
  letter-spacing: 5px;
}
.title11{
  font-size:9vw;
  font-weight: 1000;
  color:black;
  letter-spacing: 5px;
}
.title3{
  font-size:3vw;
  font-weight: 1000;
  color:orange;
  letter-spacing: 7px;
  padding-top: 1.5rem;
}
.title9{
  font-size:3vw;
  font-weight: 1000;
  color:orange;
  letter-spacing: 7px;
  padding-top: 0.5rem;
}
.vv1{
  font-weight: 700;
  font-size:large
}
.pad11{
  padding-top: 0.5rem;
}
.col3{
  color:rgb(123,18,18);
}
.playsiz{
  object-fit: contain;
}
.h5{
  letter-spacing: 2px;
}
.anim{
    /* Initial position */
    opacity: 0; /* Initially hide the text */
    animation: fadeIn 1.5s ease-in-out forwards; /* Define the animation */
  }
  .introname{
    color:orange;
  }
  iframe {
    transition: transform 0.3s ease-in-out; /* Smooth transition */
  }
  
  iframe:hover {
    transform: scale(1.1); /* Enlarge by 10% on hover */
  }
  
  @keyframes moveDown {
    from { transform: translateY(-100%); }
    to { transform: translateY(0); }
  }

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .left1 {
    /* Adjust width for smaller screens */
    width: calc(80vw - 10px);
  }
}

@media (max-width: 480px) {
  .left1{
    /* Adjust width for even smaller screens */
    width: calc(90vw - 5px);
  }
}

.scroll-container {
  display: scroll-positioned;
}


/* Define the scroll-trigger animation (adjust as needed) */

.video-container {
  position: fixed;  /* Ensure stays fixed on the screen */
  width: 100vw;
  height: 100vh; /* Ensure full viewport height */
  overflow: hidden;  /* Hide any overflow */
}

.video-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;  /* Maintain video aspect ratio while filling the container */
  pointer-events: none;  /* Prevent clicks from passing through to the iframe */
}
.contact{
  color:white
}
.top7{
  padding-top: 2rem
}






@import url('https://fonts.googleapis.com/css?family=Krub:400,700');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  background: #202020;
  font-family: 'Krub', sans-serif;
}

.card0 {
  position: absolute;
  margin: auto;
  /* top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  width: 250px;
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 10px 25px 5px rgba(0, 0, 0, 0.2);
  background: #151515;
  overflow: hidden;
  .ds-top {
    position: relative;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    width: 300px;
    height: 80px;
    background: crimson;
    animation: dsTop 1.5s;
  }
  .avatar-holder {
    position: absolute;
    margin: auto;
    top: 40px;
    right: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 0 5px #151515,
                inset 0 0 0 5px #000000,
                inset 0 0 0 5px #000000,
                inset 0 0 0 5px #000000,
                inset 0 0 0 5px #000000,;
    background: white;
    overflow: hidden;
    animation: mvTop 1.5s;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    position: absolute;
    margin: auto;
    top: -60px;
    right: 0;
    bottom: 0;
    left: 0;
    width: inherit;
    height: 40px;
    text-align: center;
    animation: fadeIn 2s ease-in;
    a {
      color: white;
      text-decoration: none;
      font-weight: 700;
      font-size: 18px;
        &:hover {
          text-decoration: underline;
          color: crimson;
      }
    }
    h6 {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      color: white;
      width: 40px;
    }
  }
  .button {
    position: absolute;
    margin: auto;
    padding: 8px;
    top: 20px;
    right: 0;
    bottom: 0;
    left: 0;
    width: inherit;
    height: 40px;
    text-align: center;
    animation: fadeIn 2s ease-in;
    outline: none;
    a {
      padding: 5px 20px;
      border-radius: 10px;
      color: white;
      letter-spacing: 0.05em;
      text-decoration: none;
      font-size: 10px;
      transition: all 1s;
      &:hover {
        color: white;
        background: crimson;
      }
    }
  }
  .ds-info {
    position: absolute;
    margin: auto;
    top: 120px;
    bottom: 0;
    right: 0;
    left: 0;
    width: inherit;
    height: 40px;
    display: flex;
    .pens, .projects, .posts {
      position: relative;
      left: -300px;
      width: calc(250px / 3);
      text-align: center;
      color: white;
      animation: fadeInMove 2s;
      animation-fill-mode: forwards;
      h6 {
        text-transform: uppercase;
        color: crimson;
      }
      p {
        font-size: 12px;
      }
    }
    .ds {
      &:nth-of-type(2) {
        animation-delay: .5s;
      }
      &:nth-of-type(1) {
        animation-delay: 1s;
      }
    }
  }
  .ds-skill {
    position: absolute;
    margin: auto;
    bottom: 10px;
    right: 0;
    left: 0;
    width: 200px;
    height: 100px;
    animation: mvBottom 1.5s;
    h6 {
      margin-bottom: 5px;
      font-weight: 700;
      text-transform: uppercase;
      color: crimson;
    }
    .skill {
      h6 {
        font-weight: 400;
        font-size: 8px;
        letter-spacing: 0.05em;
        margin: 4px 0;
        color: white;
      }
      .fab {
        color: crimson;
        font-size: 14px;
      }
      .bar {
        height: 5px;
        background: crimson;
        text-align: right;
        p {
          color: white;
          font-size: 8px;
          padding-top: 5px;
          animation: fadeIn 5s;
        }
        &:hover {
          background: white;
        }
      }
      .bar-html {
        width: 95%;
        animation: htmlSkill 1s;
        animation-delay: .4s;
      }
      .bar-css {
        width: 90%;
        animation: cssSkill 2s;
        animation-delay: .4s;
      }
      .bar-js {
        width: 75%;
        animation: jsSkill 3s;
        animation-delay: .4s;
      }
    }
  }
}

@keyframes fadeInMove {
  0% {
    opacity: 0;
    left: -300px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes htmlSkill {
  0% {width: 0;}
  100% {width: 95%;}
}

@keyframes cssSkill {
  0% {width: 0;}
  100% {width: 90%;}
}

@keyframes jsSkill {
  0% {width: 0;}
  100% {width: 75%;}
}

@keyframes mvBottom {
  0% {bottom: -150px;}
  100% {bottom: 10px;}
}

@keyframes mvTop {
  0% {top: -150px;}
  100% {top: 40px;}
}

@keyframes dsTop {
  0% {top: -150px;}
  100% {top: 0;}
}

.following {
  color: white;
  background: crimson;
}


.player-card {
  width: 250px;
  height: 350px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.player-card img {
  width: 100%;
  height: 150px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover; /* Ensure image covers the entire space */
}

.player-info {
  padding: 15px;
  text-align: center;
}

.player-info h2 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.player-info p {
  font-size: 1rem;
  color: BLACK;
}

